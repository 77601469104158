import Vue from 'vue'
import VueRouter from 'vue-router'

// 使用 VueRouter 插件
Vue.use(VueRouter)

// 定义路由
const routes = [
    {
        path: '/',
        component: () => import('@/page/IndexPage.vue'),
        meta: { title: '欢迎来到爱色色视频站' }  // 页面标题
    },
    {
        path: '/help',
        component: () => import('@/page/HelpPage.vue'),
        meta: { title: '资源预览' }
    },
    {
        path: '/test',
        component: () => import('@/page/TestPage.vue'),
        meta: { title: '资源预览' }
    },
    {
        path: '/helpDeatilsPage/:id',
        component: () => import('@/page/HelpDeatilsPage.vue'),
        meta: { title: '资源详情预览' }
    },
    {
        path: '/helpPlayer/:id',
        component: () => import('@/page/HelpPlayer.vue'),
        meta: { title: '资源播放' }
    },
    {
        path: '/home',
        component: () => import('@/page/HomePage.vue'),
        meta: { title: '视频主页' }
    },
    {
        path: '/hot',
        component: () => import('@/page/HotPage.vue'),
        meta: { title: '热门视频' }
    },
    {
        path: '/news',
        component: () => import('@/page/NewsPage.vue'),
        meta: { title: '最新视频' }
    },
    {
        path: '/list/:pageSize/:id',
        component: () => import('@/page/PageList.vue'),
        meta: { title: '视频列表' }
    },
    {
        path: '/detail/:id',
        component: () => import('@/page/DeatilsPage.vue'),
        meta: { title: '视频详情' }
    },
    {
        path: '/player/:id',
        component: () => import('@/page/VideoPlayer.vue'),
        meta: { title: '视频播放器' }
    },

    {
        path: '/xiaoshuo/:pageSize/:id',
        component: () => import('@/page/BooksList.vue'),
        meta: { title: '小说列表' }
    },

    {
        path: '/bookDetails/:pageSize/:id',
        component: () => import('@/page/BooksCatalog.vue'),
        meta: { title: '目录' }
    },

    {
        path: '/chapter/:bookId/:id',
        component: () => import('@/page/BooksChapter.vue'),
        meta: { title: '章节阅读' }
    }
]

// 创建路由实例
const router = new VueRouter({
    mode: 'history',  // 使用 history 模式
    routes,
    // 配置滚动行为，确保页面每次路由跳转时滚动到顶部
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            // 如果有保存的位置（比如使用浏览器前进后退按钮时）
            return savedPosition;
        } else {
            // 始终滚动到顶部
            return { x: 0, y: 0 };
        }
    }
})


// 路由守卫，动态修改页面标题
router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    }
    next(); // 继续导航
})
// 路由跳转后手动滚动到顶部
router.afterEach(() => {
    window.scrollTo(0, 0); // 强制滚动到顶部
});

export default router
<template>
  <div id="app">
    <router-view></router-view>
    <!-- 浮动清理缓存按钮 -->
    <button class="floating-button" @click="clearCacheAndReload">
      清理
    </button>
  </div>
</template>

<script>

import {nextTick} from "vue";

export default {
  name: 'App',
  components: {
  },
  methods:{

    clearCacheAndReload() {
      this.clearLocalStorageAndSession();
      this.clearCookies();
      alert('缓存和 cookies 已清理，页面即将刷新');
      if(this.$route.path == '/home'){
        // console.log("傻逼")
        return
      }
      this.$router.push('/home').then(() => {
        nextTick(() => {
          // window.scrollTo(0, 0);
          // window.location.reload();
        });
      });

    },
    clearLocalStorageAndSession() {
      localStorage.clear();
      sessionStorage.clear();
    },
    clearCookies() {
      const cookies = document.cookie.split("; ");
      cookies.forEach(cookie => {
        const cookieName = cookie.split("=")[0];
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
      });
    }
  }
}
</script>

<style>
#app {
}
.floating-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(255, 87, 34, 0.4); /* 半透明背景 */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.floating-button:hover {
  transform: scale(1.1);
}
</style>

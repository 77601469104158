import Vue from 'vue'
import App from './App.vue'

import router from './router/router'
import './assets/css/base.css';
import "video.js/dist/video-js.css";

import ViewUI from 'view-design';  // 引入 View UI
import 'view-design/dist/styles/iview.css';  // 引入样式文件

Vue.config.productionTip = false

// 检测设备类型
const isPC = () => {
  const userAgent = navigator.userAgent;
  const isMobile = /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(userAgent);
  return !isMobile;
};
// 检测是否在微信浏览器中
Vue.use(ViewUI);

// 创建全局指令
Vue.directive('img-error', {
  // 当指令绑定到元素时调用
  bind(el, binding) {
    // 原始图片地址
    const defaultImg = binding.value || 'path/to/default-placeholder.jpg';

    // 给 img 元素添加错误事件监听
    el.onerror = function() {
      el.src = defaultImg; // 加载失败时显示占位图
    };
  }
});

// 在路由守卫中进行检测
router.beforeEach((to, from, next) => {
  if (isPC()) {
    // 在此处可以重定向到移动端页面或显示提示信息
    alert('站点暂时不支持 PC 端访问，请使用手机访问。');
    // 或者重定向到其他页面
    // next({ path: '/mobile-only' });
  } else {
    next(); // 允许访问
  }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
